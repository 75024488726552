import React, { useState } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { Image, Anchor, NavBar, ModalBackground, City, useShoppingCart, toCssPrefix, ZIndex } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { Icon } from '../Icon/Icon'
import { useMobileNavbarMenu } from '../../hooks/useMobileNavbarMenu'
import { NavBarMenu } from './NavBarMenu'
import { MobileMenuSpacer } from './MainNavbar'
import { FlatLocationProductCategory } from '../../types/PgPages'

const ShoppingCart = loadable(() => import('../ShoppingCart'))

const { cssPrefix, toPrefix } = toCssPrefix('NavBarPage__')

const CenterWrapper = styled.div`
  .img-logo {
    width: 83px;
    max-height: 72px;
    height: auto;
  }
`
const ButtonCart = styled.button`
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  object-fit: contain;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  svg {
    fill: white;
  }
`
const IconNumber = styled.div<{ showNumber: boolean }>`
  min-width: 22px;
  border-radius: 4px;
  position: absolute;
  display: ${(props) => (props.showNumber ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  top: 25.5px;
  padding-left: 6px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
`

const Wrapper = styled.div`
  position: fixed;
  z-index: ${ZIndex.high};
  .${cssPrefix} {
    &menu {
      z-index: ${ZIndex.medium};
      margin-top: -90px;
    }
  }
`

interface Props {
  currentCity: City
  centerComponent?: React.ReactNode
  rightComponent?: React.ReactNode
  categories?: FlatLocationProductCategory[]
  onLogoClick?: () => void
}
const CustomNavbar = (props: Props) => {
  const { currentCity, centerComponent, rightComponent, onLogoClick, categories } = props
  const [isOpenCart, setIsOpenCart] = useState(false)
  const centerPros = centerComponent || (
    <Anchor onClick={() => (onLogoClick ? onLogoClick() : null)}>
      <Image
        src="https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg"
        alt="logo"
        className="img-logo"
      />
    </Anchor>
  )
  const {
    state: { globalQuantity },
  } = useShoppingCart()

  const menuTabs = useMobileNavbarMenu({ categories, citySlug: currentCity.slug })

  function getCartIcon() {
    if (globalQuantity === 1) return 'cart_1'
    if (globalQuantity > 1 && globalQuantity < 99) return 'cart_2'
    if (globalQuantity === 99) return 'cart_3'
    if (globalQuantity > 99) return 'cart_4'
    return 'cart_0'
  }

  const left = (
    <Anchor onClick={() => navigate(-1)}>
      <Icon iconId="arrow_left" color="white" size="24" />
    </Anchor>
  )
  const center = <CenterWrapper>{centerPros}</CenterWrapper>
  const right = rightComponent || (
    <>
      <div className="icon-container icon-container-cart">
        <ButtonCart data-test="navbar-shopping-cart" onClick={() => setIsOpenCart(true)}>
          <Icon iconId={getCartIcon()} size="24" />
          <IconNumber showNumber={globalQuantity > 1 && globalQuantity < 99}>
            {globalQuantity > 1 && globalQuantity < 99 ? globalQuantity : ''}
          </IconNumber>
        </ButtonCart>
      </div>
      {isOpenCart && (
        <ModalBackground>
          <ShoppingCart isOpenCart={isOpenCart} onCloseCart={() => setIsOpenCart(false)} currentCity={currentCity} />
        </ModalBackground>
      )}
    </>
  )
  return (
    <>
      <Wrapper>
        <NavBar left={left} center={center} right={right} />
        {!!categories?.length && <NavBarMenu tabs={menuTabs ?? []} className={toPrefix('menu')} />}
      </Wrapper>
      <MobileMenuSpacer className="navbar-menu-spacer" />
    </>
  )
}
export const NavBarPage = (props: Props) => (
  <CustomNavbar
    currentCity={props.currentCity}
    centerComponent={props.centerComponent}
    rightComponent={props.rightComponent}
    categories={props.categories}
  />
)
